/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import logo from '../../../static/logo-white.svg';
import { FaGithub, FaSlack } from 'react-icons/fa';

const Footer = () => {
  const productLinks = [
    { title: 'Dega', path: 'https://dega.factlylabs.com' },
    { title: 'VidCheck', path: 'https://vidcheck.factlylabs.com' },
    { title: 'Counting India', path: 'https://countingindia.com' },
    { title: 'MandE', path: '#' },
    { title: 'Parlens', path: '#' },
    { title: 'Bindu', path: '#' },
  ];
  let resourceLinks; //= [{ title: 'Blog', path: '#' }];
  const companyLinks = [{ title: 'About', path: '/about' }];
  return (
    <footer sx={{ bg: '#101010', color: '#8d8e92' }}>
      <div sx={{ maxWidth: '1024px', mx: 'auto', p: '2rem 2rem 0.5rem' }}>
        <div sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <img src={logo} alt="" width="150px" sx={{ fill: 'white', stroke: 'white' }} />
          </div>
          <div>
            <ul
              sx={{
                display: 'flex',
                listStyleType: 'none',
                m: 0,
                p: 0,
                gap: '1rem',
                '& a:hover': { color: 'white' },
              }}
            >
              <li>
                <a href="https://github.com/factly" target="_blank">
                  <FaGithub />
                </a>
              </li>
              <li>
                <a href="http://slack.factly.org/" target="_blank" rel="noopener noreferrer">
                  <FaSlack />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <div sx={{ width: ['100%', null, '40%'] }}>
            <h4 sx={{ fontSize: '1.25rem', color: 'white', my: '1rem' }}>About Factly</h4>
            <p sx={{ fontSize: ['.75rem', null, '.875rem'] }}>
              FACTLY is one of the well known Data Journalism/Public Information portals in India.
              Each news story on FACTLY is backed by factual evidence/data from official sources
              that is either available in the public domain or that is collated/gathered/collected
              using tools such as the Right to Information (RTI).
            </p>
          </div>

          <div
            sx={{
              display: 'flex',
              flexWrap: ['wrap', null, 'nowrap'],
              gap: ['1rem', null, '3rem'],
              mt: '1rem',
              fontSize: '0.75rem',
              '&>div ul': { margin: 0, padding: 0, listStyleType: 'none' },
              '&>div ul li': { mb: '.5rem' },
              '&>div span': {
                color: 'white',
                mb: '1rem',
                display: 'inline-block',
              },
            }}
          >
            {productLinks && (
              <div>
                <span>Products</span>
                <ul>
                  {productLinks.map((product) => (
                    <li>
                      <a href={product.path}>{product.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {companyLinks && (
              <div>
                <span>Company</span>
                <ul>
                  {companyLinks.map((item) => (
                    <li>
                      <a href={item.path}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {resourceLinks && (
              <div>
                <span>Resources</span>
                <ul>
                  {resourceLinks.map((resource) => (
                    <li>
                      <a href={resource.path}>{resource.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
          sx={{
            display: 'flex',
            fontSize: '0.75rem',
            textAlign: 'right',
            color: '#8d8e92',
            p: '.5rem',
            pt: '.75rem',
            justifyContent: 'flex-end',
          }}
        >
          © 2014-2021 Factly Media &amp; Research
          {/* | Except for videos, content on this site is
        licensed under a{' '} */}
          {/* <a
          rel="license"
          href="https://creativecommons.org/licenses/by/4.0/"
          sx={{ display: 'inline-block' }}
        >
          Creative Commons Attribution 4.0 International License
        </a>
        .
        <a
          rel="license"
          href="https://creativecommons.org/licenses/by/4.0/"
          sx={{ display: 'inline-block' }}
        >
          <img
            alt="Creative Commons License"
            src="https://licensebuttons.net/l/by/4.0/88x31.png"
            sx={{ display: 'inline-block' }}
            className="no-display appear"
          />
        </a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
